/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Presentation page sections
import Information from "pages/Presentation/sections/Information";
import Contact from "pages/Presentation/sections/Contact";
import CenteredFooter from "examples/Footers/CenteredFooter";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-car-5.jpg";
import { WhatsApp } from "@mui/icons-material";

// { icon: <WhatsApp />, link: "https://www.facebook.com/CreativeTim/" }
function Presentation() {
  const footerProps = {
    company: { href: "/", name: "DRIVE YOUR PROJECT" },
    links: [],
    socials: [],
    light: false,
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "tel:0766344338",
          label: "+33 7 66 34 43 38",
          color: "dark",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        color="black"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              DRIVE YOUR PROJECT{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Nous vous accompagnons dans vos déplacements en région parisienne et dans
              toute la France
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card>
        <Information />
        <Contact />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter links={footerProps.links} socials={footerProps.socials} />
      </MKBox>
    </>
  );
}

export default Presentation;
