/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/examples/blog2.jpg";

// Custom modules
import postEmail from 'email';

function Contact() {
  const [contactInfos, setContactInfos] = useState({});
  const [validForm, setValidForm] = useState(true);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);




  const sendEmail = () => {
    setValidForm(true)
    setSended(true)
    setLoading(true)

    if(contactInfos.name 
      && contactInfos.email
      && contactInfos.phone 
      && (!contactInfos.description || contactInfos.description && contactInfos.description.length <= 200))
    {
      console.log(contactInfos)
      postEmail(contactInfos).then(res => {
        console.log(res)
        setLoading(false)
        // todo valid from on ko
      }).catch( err => {
        setLoading(false)
      })
      setSended(true)
    }
    else {
      setValidForm(false)
      setLoading(false)
    }
  }
  return (
      <MKBox component="section" py={{ xs: 0, lg: 6 }} bgColor="#DDDDDD">
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Prenez rendez-vous !
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Complétez le formulaire ci-contre pour prendre rendez-vous. Nous reviendrons
                      Vers vous dans les plus brefs délais.
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+33) 07 66 34 43 38
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        drive.your.projects@gmail.com
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Nous sommes à votre service
                    </MKTypography>
                    {
                      !sended ? <MKTypography variant="body1" color="text" mb={2}>
                                  Renseignez vos coordonnées pour pouvoir être rappelé par nos services.Vous
                                  recevrez un email de confirmation une fois le formulaire rempli.
                               </MKTypography>
                              : !validForm ? <MKTypography variant="body1" color="error" mb={2}>
                                                Une erreur s'est produite. Vérifiez que vous avez rempli les champs requis et reessayez.
                                            </MKTypography>
                                          : <div>
                                              <MKTypography variant="body1" color="success" mb={2}>
                                                Votre formulaire a été validé avec succès. Vous recevrez un email de confirmation de reception.
                                              </MKTypography>
                                              <MKTypography variant="body2" color="text" mb={2}>
                                                Si vous n'avez pas reçu d'email : <br/>
                                                * Vérifiez vos spams <br/>
                                                * Reéssayez avec une autre adresse mail <br/>
                                                * Contactez nous directement par notre email ou par téléphone.
                                              </MKTypography>
                                            </div>
                    }
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Comment vous appelez-vous ?"
                          placeholder="Nom et prénom"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            contactInfos.name = e.target.value
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          type="email"
                          variant="standard"
                          label="Email"
                          placeholder="Quel est votre Email ?"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            contactInfos.email = e.target.value
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          type="phone"
                          variant="standard"
                          label="Téléphone"
                          placeholder="Votre numéro de téléphone"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            contactInfos.phone = e.target.value
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Commentaire"
                          placeholder="Décrivez nous ce que vous voulez"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          onChange={(e) => {
                            contactInfos.description = e.target.value
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton 
                        variant="gradient"
                        color="dark"
                        onClick={sendEmail}
                        disabled={loading}>
                        Envoyer
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
