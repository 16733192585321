/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
// import team1 from "assets/images/team-5.jpg";
// import team2 from "assets/images/bruce-mars.jpg";
// import team3 from "assets/images/ivana-squares.jpg";
// import team4 from "assets/images/ivana-square.jpg";
import mackbook from "assets/images/macbook.png";
import professional from "assets/images/illustrations/professional.jpeg";
import economy from "assets/images/illustrations/economy.jpeg";
import time from "assets/images/illustrations/time.jpeg";
import flexible from "assets/images/illustrations/flexible.jpeg";


function Team() {
  return (
    <MKBox
      component="section"
      bgColor="#333333"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Pourquoi nous solliciter ?
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={professional}
                name="Professionnalisme"
                position={{ color: "info", label: "" }}
                description="Avec nous, vous avez la garantie de passer des trajets agréable dans une voiture 
                confortable"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={time}
                name="Ponctualité"
                position={{ color: "info", label: "" }}
                description="La ponctualité est notre priorité. Nous respectons votre temps et tenons nos engagements"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={economy}
                name="Prix compétitif"
                position={{ color: "info", label: "" }}
                description="Nous faisons en sorte d'avoir les meilleurs prix sur le marché"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={flexible}
                name="Services flexibles"
                position={{ color: "info", label: "" }}
                description="Nous pouvons vous accompagner dans tous vos besoins de déplacement en France"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
